var countriesList = [
  { value: 'afghanistan', text: 'Afghanistan' },
  { value: 'aland Islands', text: 'Aland Islands' },
  { value: 'albania', text: 'Albania' },
  { value: 'algeria', text: 'Algeria' },
  { value: 'american samoa', text: 'American Samoa' },
  { value: 'andorra', text: 'Andorra' },
  { value: 'angola', text: 'Angola' },
  { value: 'anguilla', text: 'Anguilla' },
  { value: 'antarctica', text: 'Antarctica' },
  { value: 'antigua and aarbuda', text: 'Antigua and Barbuda' },
  { value: 'argentina', text: 'Argentina' },
  { value: 'armenia', text: 'Armenia' },
  { value: 'aruba', text: 'Aruba' },
  { value: 'australia', text: 'Australia' },
  { value: 'austria', text: 'Austria' },
  { value: 'azerbaijan', text: 'Azerbaijan' },
  { value: 'bahamas', text: 'Bahamas' },
  { value: 'bahrain', text: 'Bahrain' },
  { value: 'bangladesh', text: 'Bangladesh' },
  { value: 'barbados', text: 'Barbados' },
  { value: 'belarus', text: 'Belarus' },
  { value: 'belgium', text: 'Belgium' },
  { value: 'belize', text: 'Belize' },
  { value: 'benin', text: 'Benin' },
  { value: 'bermuda', text: 'Bermuda' },
  { value: 'bhutan', text: 'Bhutan' },
  { value: 'bolivia', text: 'Bolivia' },
  { value: 'bosnia and herzegovina', text: 'Bosnia and Herzegovina' },
  { value: 'botswana', text: 'Botswana' },
  { value: 'bouvet island', text: 'Bouvet Island' },
  { value: 'brazil', text: 'Brazil' },
  { value: 'british indian ocean territory', text: 'British Indian Ocean Territory' },
  { value: 'brunei darussalam', text: 'Brunei Darussalam' },
  { value: 'bulgaria', text: 'Bulgaria' },
  { value: 'burkina faso', text: 'Burkina Faso' },
  { value: 'burundi', text: 'Burundi' },
  { value: 'cambodia', text: 'Cambodia' },
  { value: 'cameroon', text: 'Cameroon' },
  { value: 'canada', text: 'Canada' },
  { value: 'cape verde', text: 'Cape Verde' },
  { value: 'cayman islands', text: 'Cayman Islands' },
  { value: 'central african republic', text: 'Central African Republic' },
  { value: 'chad', text: 'Chad' },
  { value: 'chile', text: 'Chile' },
  { value: 'china', text: 'China' },
  { value: 'christmas island', text: 'Christmas Island' },
  { value: 'cocos islands', text: 'Cocos (Keeling) Islands' },
  { value: 'colombia', text: 'Colombia' },
  { value: 'comoros', text: 'Comoros' },
  { value: 'congo', text: 'Congo' },
  { value: 'cook islands', text: 'Cook Islands' },
  { value: 'costa rica', text: 'Costa Rica' },
  { value: "Cote D'ivoire", text: "Cote D'ivoire" },
  { value: 'croatia', text: 'Croatia' },
  { value: 'cuba', text: 'Cuba' },
  { value: 'cyprus', text: 'Cyprus' },
  { value: 'czech republic', text: 'Czech Republic' },
  { value: 'denmark', text: 'Denmark' },
  { value: "democratic people's republic of korea", text: "Democratic People's Republic of Korea" },
  { value: 'djibouti', text: 'Djibouti' },
  { value: 'dominica', text: 'Dominica' },
  { value: 'dominican republic', text: 'Dominican Republic' },
  { value: 'ecuador', text: 'Ecuador' },
  { value: 'egypt', text: 'Egypt' },
  { value: 'el salvador', text: 'El Salvador' },
  { value: 'equatorial guinea', text: 'Equatorial Guinea' },
  { value: 'eritrea', text: 'Eritrea' },
  { value: 'estonia', text: 'Estonia' },
  { value: 'ethiopia', text: 'Ethiopia' },
  { value: 'falkland islands (Malvinas)', text: 'Falkland Islands (Malvinas)' },
  { value: 'faroe islands', text: 'Faroe Islands' },
  { value: 'fiji', text: 'Fiji' },
  { value: 'finland', text: 'Finland' },
  { value: 'france', text: 'France' },
  { value: 'french guiana', text: 'French Guiana' },
  { value: 'french polynesia', text: 'French Polynesia' },
  { value: 'french southern territories', text: 'French Southern Territories' },
  { value: 'gabon', text: 'Gabon' },
  { value: 'gambia', text: 'Gambia' },
  { value: 'georgia', text: 'Georgia' },
  { value: 'germany', text: 'Germany' },
  { value: 'ghana', text: 'Ghana' },
  { value: 'gibraltar', text: 'Gibraltar' },
  { value: 'greece', text: 'Greece' },
  { value: 'greenland', text: 'Greenland' },
  { value: 'grenada', text: 'Grenada' },
  { value: 'guadeloupe', text: 'Guadeloupe' },
  { value: 'guam', text: 'Guam' },
  { value: 'guatemala', text: 'Guatemala' },
  { value: 'guernsey', text: 'Guernsey' },
  { value: 'guinea', text: 'Guinea' },
  { value: 'guinea-bissau', text: 'Guinea-bissau' },
  { value: 'guyana', text: 'Guyana' },
  { value: 'haiti', text: 'Haiti' },
  { value: 'vatican city state', text: 'Vatican City State' },
  { value: 'honduras', text: 'Honduras' },
  { value: 'hong kong', text: 'Hong Kong' },
  { value: 'hungary', text: 'Hungary' },
  { value: 'iceland', text: 'Iceland' },
  { value: 'india', text: 'India' },
  { value: 'indonesia', text: 'Indonesia' },
  { value: 'iran', text: 'Iran' },
  { value: 'iraq', text: 'Iraq' },
  { value: 'ireland', text: 'Ireland' },
  { value: 'isle of man', text: 'Isle of Man' },
  { value: 'israel', text: 'Israel' },
  { value: 'italy', text: 'Italy' },
  { value: 'jamaica', text: 'Jamaica' },
  { value: 'japan', text: 'Japan' },
  { value: 'jersey', text: 'Jersey' },
  { value: 'jordan', text: 'Jordan' },
  { value: 'kazakhstan', text: 'Kazakhstan' },
  { value: 'kenya', text: 'Kenya' },
  { value: 'kiribati', text: 'Kiribati' },
  { value: 'kuwait', text: 'Kuwait' },
  { value: 'kyrgyzstan', text: 'Kyrgyzstan' },
  { value: "lao people's democratic republic", text: "Lao People's Democratic Republic" },
  { value: 'latvia', text: 'Latvia' },
  { value: 'lebanon', text: 'Lebanon' },
  { value: 'lesotho', text: 'Lesotho' },
  { value: 'liberia', text: 'Liberia' },
  { value: 'libyan arab jamahiriyac', text: 'Libyan Arab Jamahiriya' },
  { value: 'liechtenstein', text: 'Liechtenstein' },
  { value: 'lithuania', text: 'Lithuania' },
  { value: 'luxembourg', text: 'Luxembourg' },
  { value: 'macao', text: 'Macao' },
  { value: 'north macedonia', text: 'North Macedonia' },
  { value: 'madagascar', text: 'Madagascar' },
  { value: 'malawi', text: 'Malawi' },
  { value: 'malaysia', text: 'Malaysia' },
  { value: 'maldives', text: 'Maldives' },
  { value: 'mali', text: 'Mali' },
  { value: 'malta', text: 'Malta' },
  { value: 'marshall islands', text: 'Marshall Islands' },
  { value: 'martinique', text: 'Martinique' },
  { value: 'mauritania', text: 'Mauritania' },
  { value: 'mauritius', text: 'Mauritius' },
  { value: 'mayotte', text: 'Mayotte' },
  { value: 'mexico', text: 'Mexico' },
  { value: 'micronesia', text: 'Micronesia' },
  { value: 'moldova', text: 'Moldova' },
  { value: 'monaco', text: 'Monaco' },
  { value: 'mongolia', text: 'Mongolia' },
  { value: 'montenegro', text: 'Montenegro' },
  { value: 'montserrat', text: 'Montserrat' },
  { value: 'morocco', text: 'Morocco' },
  { value: 'mozambique', text: 'Mozambique' },
  { value: 'myanmar', text: 'Myanmar' },
  { value: 'namibia', text: 'Namibia' },
  { value: 'nauru', text: 'Nauru' },
  { value: 'nepal', text: 'Nepal' },
  { value: 'netherlands', text: 'Netherlands' },
  { value: 'netherlands antilles', text: 'Netherlands Antilles' },
  { value: 'new caledonia', text: 'New Caledonia' },
  { value: 'new zealand', text: 'New Zealand' },
  { value: 'nicaragua', text: 'Nicaragua' },
  { value: 'niger', text: 'Niger' },
  { value: 'nigeria', text: 'Nigeria' },
  { value: 'niue', text: 'Niue' },
  { value: 'norfolk island', text: 'Norfolk Island' },
  { value: 'northern mariana islands', text: 'Northern Mariana Islands' },
  { value: 'norway', text: 'Norway' },
  { value: 'oman', text: 'Oman' },
  { value: 'pakistan', text: 'Pakistan' },
  { value: 'palau', text: 'Palau' },
  { value: 'panama', text: 'Panama' },
  { value: 'papua new guinea', text: 'Papua New Guinea' },
  { value: 'paraguay', text: 'Paraguay' },
  { value: 'peru', text: 'Peru' },
  { value: 'philippines', text: 'Philippines' },
  { value: 'pitcairn', text: 'Pitcairn' },
  { value: 'poland', text: 'Poland' },
  { value: 'portugal', text: 'Portugal' },
  { value: 'puerto rico', text: 'Puerto Rico' },
  { value: 'qatar', text: 'Qatar' },
  { value: 'reunion', text: 'Reunion' },
  { value: 'romania', text: 'Romania' },
  { value: 'russian federation', text: 'Russian Federation' },
  { value: 'rwanda', text: 'Rwanda' },
  { value: 'saint helena', text: 'Saint Helena' },
  { value: 'samoa', text: 'samoa' },
  { value: 'san marino', text: 'San Marino' },
  { value: 'saudi arabia', text: 'Saudi Arabia' },
  { value: 'senegal', text: 'Senegal' },
  { value: 'serbia', text: 'Serbia' },
  { value: 'seychelles', text: 'Seychelles' },
  { value: 'sierra leone', text: 'Sierra Leone' },
  { value: 'singapore', text: 'Singapore' },
  { value: 'slovakia', text: 'Slovakia' },
  { value: 'slovenia', text: 'Slovenia' },
  { value: 'solomon islands', text: 'Solomon Islands' },
  { value: 'somalia', text: 'Somalia' },
  { value: 'south africa', text: 'South Africa' },
  { value: 'south korea', text: 'South Korea' },
  { value: 'spain', text: 'Spain' },
  { value: 'sri lanka', text: 'Sri Lanka' },
  { value: 'sudan', text: 'Sudan' },
  { value: 'suriname', text: 'Suriname' },
  { value: 'swaziland', text: 'Swaziland' },
  { value: 'sweden', text: 'Sweden' },
  { value: 'switzerland', text: 'Switzerland' },
  { value: 'syrian arab republic', text: 'Syrian Arab Republic' },
  { value: 'taiwan', text: 'Taiwan' },
  { value: 'tajikistan', text: 'Tajikistan' },
  { value: 'tanzania', text: 'Tanzania' },
  { value: 'thailand', text: 'Thailand' },
  { value: 'togo', text: 'Togo' },
  { value: 'tonga', text: 'Tonga' },
  { value: 'trinidad and tobago', text: 'Trinidad and Tobago' },
  { value: 'tunisia', text: 'Tunisia' },
  { value: 'turkey', text: 'Turkey' },
  { value: 'turkmenistan', text: 'Turkmenistan' },
  { value: 'tuvalu', text: 'Tuvalu' },
  { value: 'uganda', text: 'Uganda' },
  { value: 'ukraine', text: 'Ukraine' },
  { value: 'united arab emirates', text: 'United Arab Emirates' },
  { value: 'united kingdom', text: 'United Kingdom' },
  { value: 'united states', text: 'United States' },
  { value: 'uruguay', text: 'Uruguay' },
  { value: 'uzbekistan', text: 'Uzbekistan' },
  { value: 'vanuatu', text: 'Vanuatu' },
  { value: 'venezuela', text: 'Venezuela' },
  { value: 'vietnam', text: 'Vietnam' },
  { value: 'virgin islands', text: 'Virgin Islands' },
  { value: 'wallis and futuna', text: 'Wallis and Futuna' },
  { value: 'yemen', text: 'Yemen' },
  { value: 'zambia', text: 'Zambia' },
  { value: 'zimbabwe', text: 'Zimbabwe' }
]
export default countriesList
